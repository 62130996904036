import React from "react";
import EmailEditor from "react-email-editor";
import axios from "axios";
import Swal from "sweetalert2";

const containerStyle = {
  height: "99vh",
  width: "99vw",
  position: "relative",
};

const buttonStyle = {
  position: "absolute",
  bottom: "20px",
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: "#1d3557",
  color: "white",
  padding: "14px 20px",
  margin: "8px 0",
  border: "none",
  cursor: "pointer",
  borderRadius: "30px",
  fontSize: "16px",
  transition: "all 0.5s",
};

const buttonHoverStyle = {
  backgroundColor: "#000000",
};

class App extends React.Component {
  editor = null;

  state = {
    message: null,
  };

  saveDesign = () => {
    console.log("Saving design...");
    this.editor.exportHtml((data) => {
      const { design, html } = data;
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("id");
      const key = urlParams.get("key");

      console.log("ID:", id);
      console.log("Key:", key);
      console.log("Design:", design);
      console.log("HTML:", html);

      axios
        .post(`https://crm.stelli-studio.co.il/crm-api/designsNew.php`, {
          id,
          key,
          design,
          html,
        })
        .then((response) => {
          console.log("Success:", response.data);
          Swal.fire({
            title: "עבודה מדהימה",
            text: "נשמר בהצלחה",
            icon: "success",
            showCancelButton: true, // This adds a second button
            confirmButtonText: "חזרה לאתר", // This will be the text of the second button
            cancelButtonText: "הישאר בעמוד", // Default text for the default button
          }).then((result) => {
            if (result.isConfirmed) {
              window.close();
            }
          });
        })
        .catch((error) => {
          Swal.fire("Error!", "There was an error saving the design.", "error");
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error("Error response:", error.response);
            console.error("Error status:", error.response.status);
            console.error("Error data:", error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.error("Error request:", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error message:", error.message);
          }
          console.error("Error config:", error.config);
        });
    });
  };

  onDesignLoad = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const key = urlParams.get("key");
    const status = urlParams.get("status");

    if (status == "new") {
      console.log("Creating a new design...");
      // Add your code to handle new design creation
    } else {
      console.log("Loading design for ID:", id);

      fetch(
        `https://crm.stelli-studio.co.il/crm-api/designsNew.php?id=${id}&key=${key}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Loaded design:", data);
          if (data.success === false) {
            // Handle the error case
            console.error("Error loading design:", data.message);
          } else {
            // If there's no error, load the design
            if (data.design !== "default") {
              this.editor.loadDesign(data);
            } else {
              console.log("Default design, not loading.");
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  closeTab = () => {
    window.close();
  };

  render() {
    return (
      <div style={containerStyle}>
        {this.state.message && (
          <div
            style={{
              color: "green",
              position: "absolute",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {this.state.message}
          </div>
        )}
        <EmailEditor
          style={containerStyle}
          ref={(editor) => (this.editor = editor)}
          onLoad={this.onDesignLoad}
          options={{
            textDirection: "rtl",
            locale: "he",
            displayMode: "email",
            appearance: {
              theme: "dark",
            },
            tools: {
              text: {
                properties: {
                  textAlign: {
                    value: "right",
                  },
                  text: {
                    value:
                      "טקסט לדוגמא למאמר באתר טקסט לדוגמא למאמר באתר טקסט לדוגמא למאמר באתר טקסט לדוגמא למאמר באתר טקסט לדוגמא למאמר באתר טקסט לדוגמא למאמר באתר",
                  },
                },
              },
              heading: {
                properties: {
                  textAlign: {
                    value: "right",
                  },
                  text: {
                    value: "כותרת",
                  },
                },
              },
            },
          }}
        />
        <button
          style={buttonStyle}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor =
              buttonHoverStyle.backgroundColor)
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor =
              buttonStyle.backgroundColor)
          }
          onClick={this.saveDesign}
        >
          שמירה
        </button>
      </div>
    );
  }
}

export default App;
